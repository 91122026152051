import { _js } from '@ifixit/localize';
import tippy from 'tippy.js';
import 'Shared/tippy-legacy-theme.css';
import { MediaManager } from 'Shared/FrameModules/MediaLibrary/media_manager';
import { MinimalMediaTarget } from 'Shared/FrameModules/MediaLibrary/minimal_target';
import { FormLibrary } from 'Shared/utils';
import { Modal } from 'Shared/modal';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

export const WikiTextEditor = new Class({
   Implements: [Options],

   options: {
      toolbarItems: ['heading', 'bold', 'italic', 'underline', 'ul', 'ol', 'link'],
      className: 'wikiTextEditorToolbar',
      helpLink: null,
      helpText: null,
      listHelper: true,
      defaultOpenInNewTab: false,
   },

   textarea: null,
   toolbar: null,
   bindings: {},
   selection: null,

   initialize: function (textarea, list, options) {
      this.setOptions(options);
      this.textarea = $(textarea);

      if (list) {
         this.toolbar = $(list);
      } else {
         let prev = this.textarea.getPrevious();
         let classes = this.options.className.split(' ');
         if (prev && prev.hasClass(classes[0])) {
            this.toolbar = prev;
         } else {
            this.toolbar = new Element('ul', { class: this.options.className });
            this.toolbar.inject(this.textarea, 'before');
         }
      }

      this.buildToolbar();

      this.installKeyListener();

      this.installClipboardListener();

      if (this.options.listHelper) {
         this.installListHelper();
      }
   },

   buildToolbar: function () {
      if (this.options.helpLink) {
         this.toolbar.adopt(
            new Element('li', {
               class: 'toolbarHelp',
            }).adopt(
               new Element('a', {
                  href: this.options.helpLink,
                  class: 'muted',
                  alt: this.options.helpText || _js('Help'),
                  title: this.options.helpText || _js('Help'),
                  target: '_BLANK',
               }).adopt(
                  new Element('i', {
                     class: 'fa fa-question-circle',
                  })
               )
            )
         );
      }

      this.options.toolbarItems.forEach(item => {
         item = this.toolbarItems[item];
         let type = item[0].capitalize(); // e.g. Heading
         let builder = 'add' + type + 'Item'; // e.g. addHeadingItem
         this[builder].apply(this, Array.convert(item.slice(1))); // pass all but type
      });
   },

   toolbarItems: {
      heading: ['heading', 2, _js('Make heading')],

      bold: ['wrapper', 'Bold', '***', _js('bold text'), _js('Make text bold'), 'C-b'],

      italic: ['wrapper', 'Italic', "''", _js('italic text'), _js('Make text italic'), 'C-i'],

      underline: [
         'wrapper',
         'Underline',
         '++',
         _js('Underlined text'),
         _js('Make text underlined'),
         'C-u',
      ],

      ul: ['list', 'List', '*', _js('Make list'), 'C-2'],

      ol: ['list', 'Numbered list', '#', _js('Make numbered list'), 'C-3'],

      link: ['link', 'C-l'],

      media: ['media'],

      image: ['image'],
      video: ['video'],
   },

   addHeadingItem: function (level, title) {
      let id = this.makeItemId('Heading' + level);
      let name = _js('Heading') + ' ' + level;
      let wrapper = '';
      for (let i = 0; i < level; i++) {
         wrapper += '=';
      }
      this.addFunction(
         name,
         function () {
            this.wrapSelection({
               before: wrapper + ' ',
               defaultMiddle: _js('Heading text'),
               after: ' ' + wrapper,
            });
         },
         {
            class: id,
            icon: 'header',
            tooltip: title,
         }
      );
   },

   addWrapperItem: function (name, wrapper, defaultText, title, binding) {
      let id = this.makeItemId(name);
      this.addFunction(
         name,
         function () {
            this.wrapSelection({
               before: wrapper,
               defaultMiddle: defaultText,
               after: wrapper,
            });
            if (binding) {
               return false;
            }
         },
         {
            class: id,
            icon: name.toLowerCase(),
            tooltip: title,
         },
         binding
      );
   },

   addListItem: function (name, bullet, title, binding) {
      let id = this.makeItemId(name);
      let explain = _js(
         'Hit <enter> to insert another list item, and <enter> twice in a row to end the list. To get subitems, repeat the bullet character once for each level of indent you want (e.g. %1%1)',
         '<bullet>'
      );
      let defaultText = '<bullet> ' + explain;
      this.addFunction(
         name,
         function () {
            this.replaceMultilineSelection({
               eachLine: function (line) {
                  return bullet + ' ' + line;
               },
               defaultText: {
                  text: defaultText.replace(/<bullet>/g, bullet),
                  select: explain.replace(/<bullet>/g, bullet),
               },
            });
            if (binding) {
               return false;
            }
         },
         {
            class: id,
            icon: bullet === '*' ? 'list-ul' : 'list-ol',
            tooltip: title,
         },
         binding
      );
   },

   addLinkItem: function () {
      this.addFunction(
         _js('Link'),
         function () {
            let selection = getSelectedText(this.textarea);
            let defaultUrl = '';
            let defaultLinkText = '';
            let defaultNewTabCheckbox = this.options.defaultOpenInNewTab ? 'checked' : '';

            if (selection !== '') {
               if (selection.test(/^https?:\/\//)) {
                  defaultUrl = selection;
               } else if (selection.test(/^([^\s./]+\.)+[^\s./]{2,7}/)) {
                  // Try to be forgiving of things that look like links, but lack
                  // a protocol.
                  defaultUrl = 'http://' + selection;
               } else {
                  defaultLinkText = selection;
               }
            }

            Modal.open({
               type: 'form',
               defaultWidth: true,
               form: FormLibrary.create({
                  id: 'wikiTextEditorLinkForm',
                  class: 'wikiTextEditorModal',
                  fields: {
                     url: {
                        label: _js('Link URL'),
                        type: 'text',
                        value: defaultUrl,
                        properties: { id: 'wikiTextEditorURLEntry' },
                     },
                     linkText: {
                        label: _js('Link Text (optional)'),
                        type: 'text',
                        value: defaultLinkText,
                        properties: { id: 'wikiTextEditorLinkTextEntry' },
                     },
                     openInNewTab: {
                        label: _js('Open in a new tab'),
                        type: 'switch',
                        value: this.options.defaultOpenInNewTab,
                        properties: {
                           id: 'wikiTextEditorOpenInNewTabEntry',
                           checked: defaultNewTabCheckbox,
                        },
                     },
                  },
                  submit: _js('Insert link'),
               }),
               onSubmit: function (form) {
                  let linkText = form.linkText ? '|' + form.linkText.replace(/\|/g, '') : '';
                  let openInNewTab = form.openInNewTab;
                  let newTabText = openInNewTab ? '|new_window=true' : '';
                  let url = form.url;
                  if (!/https?:\/\//.test(url)) {
                     url = 'http://' + url;
                  }
                  // We know that this is supposed to be text free of wiki syntax,
                  // so we're free to encode it.
                  url = this.encodeWikiSyntax(url);
                  url = url.replace(/%7Cnew_window=true\s*/, '|new_window=true');
                  this.setStoredSelection();
                  insertAtCursor(this.textarea, '[link|' + url + newTabText + linkText + ']');
               }.bind(this),
               onLoaded: function () {
                  if (defaultUrl) {
                     $('wikiTextEditorLinkTextEntry').focus();
                  }
               },
            });

            let cancelButton = new Element('button', {
               type: 'button',
               class: 'button cancel-link',
               text: _js('Cancel'),
            }).addEvent('click', Modal.cancel);

            $$('.wikiTextEditorModal .formBody').adopt(cancelButton);
         },
         {
            class: this.makeItemId('Link'),
            icon: 'link',
            tooltip: _js('Insert a link'),
         }
      );
   },

   addVideoItem: function () {
      this.addFunction(
         _js('Video'),
         function () {
            let selection = getSelectedText(this.textarea);

            let beforeTag = '[video|';

            if (selection !== '') {
               if (selection.test(/^https?:\/\//)) {
                  this.replaceSelectionWithTemplate({
                     before: beforeTag,
                     select: selection + ']' + _js('Video caption'),
                     after: '[/video]',
                  });
               } else if (selection.test(/^([^\s./]+\.)+[^\s./]{2,7}/)) {
                  // Try to be forgiving of things that look like links, but lack
                  // a protocol.
                  this.replaceSelectionWithTemplate({
                     before: beforeTag,
                     select: selection + ']' + _js('Video caption'),
                     after: '[/video]',
                  });
               } else {
                  this.replaceSelectionWithTemplate({
                     before: beforeTag,
                     select: '{' + _js('Insert Video URL Here') + '}',
                     after: ']',
                  });
               }
               return;
            }

            Modal.open({
               type: 'form',
               defaultWidth: true,
               form: FormLibrary.create({
                  id: 'wikiTextEditorVideoForm',
                  class: 'wikiTextEditorModal',
                  fields: {
                     videoUrl: {
                        label: _js('YouTube or Vimeo Video URL'),
                        type: 'text',
                        properties: { id: 'wikiTextEditorVideoURLEntry' },
                     },
                     videoCaption: {
                        label: _js('Video Caption (optional)'),
                        type: 'text',
                        properties: { id: 'wikiTextEditorVideoTextEntry' },
                     },
                  },
                  submit: _js('Insert video'),
               }),
               onSubmit: function (form) {
                  let caption = form.videoCaption ? ']' + form.videoCaption + '[/video]' : '';
                  // Set the selection stored when the field lost focus.
                  this.setStoredSelection();
                  if (!/https?:\/\//.test(form.videoUrl)) {
                     form.videoUrl = 'http://' + form.videoUrl;
                  }

                  insertAtCursor(this.textarea, '[video|' + form.videoUrl + caption + ']');
               }.bind(this),
            });

            let cancelButton = new Element('button', {
               type: 'button',
               class: 'button cancel-link',
               text: _js('Cancel'),
            }).addEvent('click', Modal.cancel);

            $$('.wikiTextEditorModal .formBody').adopt(cancelButton);
         },
         {
            class: this.makeItemId('Video'),
            icon: 'video-camera',
            tooltip: _js('Insert a Youtube or Vimeo video'),
         }
      );
   },

   addImageItem: function () {
      this.addFunction(_js('Image'), () => {}, {
         class: this.makeItemId('Image'),
         icon: 'image',
         tooltip: _js('Insert an image'),
      });
   },

   addMediaItem: function () {
      let editor = this;
      this.mediaTarget = new MinimalMediaTarget({
         customAttach: function (mediaItem, finished) {
            if (mediaItem) {
               // insertAtCursor() function fails (does nothing) when
               // the field is blank and unfocused... yay. Focusing first makes
               // it work.
               editor.textarea.focus();
               insertAtCursor(editor.textarea, mediaItem.toWikiText());
            }
            // finished(oldItem, successfully attached?, error message)
            finished(null, true);
         },
         accepts: 'wiki_media',
      });
      this.addFunction(
         _js('Media'),
         () => {
            MediaManager.browse(editor.mediaTarget);
         },
         {
            class: this.makeItemId('Media'),
            icon: 'image',
            tooltip: _js('Insert an image or video'),
         }
      );
   },

   addFunction: function (name, callback, properties, binding) {
      let item = new Element('li');
      let anchor = name.replace(/[^\w -]/g, '').replace(/\s+/, '-');
      let itemlink = new Element('a', {
         events: {
            mousedown: function () {
               // Store the current selection so that we can restore it again
               // later.  This is necessary because IE loses the selection
               // information when the field is blurred.
               this.selection = {
                  start: this.textarea.selectionStart,
                  end: this.textarea.selectionEnd,
               };
            }.bind(this),
            click: function (ev) {
               ev.stop();
               // Set the selection from what we stored on mousedown.
               this.setStoredSelection();
               callback.apply(this, Array.convert(null));
               trackInPiwikAndGA({
                  eventCategory: 'Wiki Text Editor',
                  eventAction: `Wiki Text Editor - Toolbar Item Clicked - ${name}`,
               });
            }.bind(this),
         },
         href: '#' + anchor.toLowerCase(),
         // Set the tab order so that these come up last.
         tabindex: 1000,
      });
      itemlink.adopt(new Element('span', { text: name }));
      itemlink.adopt(new Element('i', { class: 'fa fa-' + properties.icon }));
      itemlink.setProperties(properties || {});
      itemlink.inject(item, 'bottom');
      item.inject(this.toolbar);

      tippy(itemlink, { content: properties.tooltip, theme: 'globalTippyStyles' });

      if (binding) {
         this.addBinding(binding, callback);
      }
   },

   makeItemId: function (name) {
      name = name.replace(/\s+/g, '-').camelCase().capitalize();
      return 'wikiTextEditor' + name + 'Item';
   },

   setStoredSelection: function () {
      if (this.selection) {
         this.textarea.focus();
         this.textarea.setSelectionRange(this.selection.start, this.selection.end);
      }
   },

   wrapSelection: function (options) {
      let selection = getSelectedText(this.textarea);
      if (selection === '') {
         insertAroundCursor(this.textarea, options);
      } else {
         let text = options.before + selection + options.after;
         insertAtCursor(this.textarea, text);
      }
   },

   replaceMultilineSelection: function (options) {
      let selection = getSelectedText(this.textarea);
      if (selection === '') {
         let start = this.textarea.selectionStart;
         let text = options.defaultText.text;
         insertAtCursor(this.textarea, text);
         if (options.defaultText.select) {
            let selectText = options.defaultText.select;
            let offset = text.indexOf(selectText);
            if (offset != -1) {
               start += offset;
               this.textarea.focus();
               this.textarea.setSelectionRange(start, start + selectText.length);
            }
         }
      } else {
         let callback = options.eachLine;
         let lines = selection.split('\n');
         let newlines = [];
         lines.forEach(line => {
            if (line !== '') {
               newlines.push(callback.apply(this, Array.convert(line)));
            }
         });
         insertAtCursor(this.textarea, newlines.join('\n'));
      }
   },

   replaceSelectionWithTemplate: function (options) {
      options = Object.merge(
         {
            before: '',
            select: '',
            after: '',
         },
         options
      );

      insertAtCursor(this.textarea, options.before);
      insertAroundCursor(this.textarea, {
         before: '',
         defaultMiddle: options.select,
         after: options.after,
      });
   },

   installListHelper: function () {
      let listBullets = Hash.getValues(this.toolbarItems)
         .map(item => (item[0] == 'list' ? item[2] : null))
         .clean()
         .join('');
      let listItemRegex = new RegExp('^([' + listBullets + ']+)(.*)$');
      let emptyListRegex = new RegExp('^[\\s' + listBullets + ']*$');
      this.addBinding('enter', function () {
         let startLen = this.textarea.selectionStart;
         let endLen = this.textarea.selectionEnd;
         let lines = this.textarea.value.slice(0, Math.max(0, startLen)).split('\n');
         let currLine = lines.pop();
         let groups = currLine.match(listItemRegex);
         if (groups) {
            if (/^\s*$/.test(groups[2])) {
               // We're ending the list, and we need to delete the last line.
               let before = lines.join('\n') + '\n\n';
               if (before.test(emptyListRegex)) {
                  before = '';
               }
               this.textarea.setSelectionRange(0, endLen);
               insertAtCursor(this.textarea, before);
               this.textarea.setSelectionRange(before.length + 1, before.length + 1);
            } else {
               // We're extending the list.
               let newText = '\n' + groups[1] + ' ';
               insertAtCursor(this.textarea, newText);
               this.textarea.setSelectionRange(
                  startLen + newText.length,
                  startLen + newText.length
               );
            }
            // Stop the event (but not other bindings).
            return false;
         }
      });
   },

   installKeyListener: function () {
      this.textarea.addEvent('keypress', ev => {
         // Construct a string code from the keys pressed.
         let keys = [];
         ['shift', 'control', 'alt', 'meta'].forEach(modifier => {
            if (ev[modifier]) {
               keys.push(modifier.capitalize().slice(0, 1));
            }
         });
         keys.push(ev.key);
         let code = keys.join('-');
         let passEvent = true;
         if (this.bindings[code]) {
            this.bindings[code].each(function (callback) {
               if (callback.apply(this, Array.convert(ev)) === false) {
                  passEvent = false;
               }
            }, this);
            if (!passEvent) {
               ev.stop();
            }
         }
      });
   },

   /**
    * Listens for paste events on the wiki input text area and intercepts
    * anything that looks like a URI (and nothing but a URI), replacing it with
    * an equivalent URI with wiki syntax (square brackets and vertical bar)
    * encoded. We can't do the same transformation across arbitrary text
    * without solving the general problem of identifying the boundaries of URIs
    * in running text.
    */
   installClipboardListener: function () {
      this.textarea.addEventListener('paste', ev => {
         let text;
         if (window.clipboardData && window.clipboardData.getData) {
            // IE
            text = window.clipboardData.getData('Text');
            ev = window.event;
         } else if (ev.clipboardData && ev.clipboardData.getData) {
            text = ev.clipboardData.getData('text/plain');
         }
         if (!text) {
            return;
         }
         if (text.test(/^https?:\/\/\S+$/)) {
            if (ev.preventDefault) {
               ev.preventDefault();
            } else {
               ev.returnValue = false;
            }
            let url = this.encodeWikiSyntax(text);
            insertAtCursor(this.textarea, url);
         }
      });
   },

   addBinding: function (code, callback) {
      if (this.bindings[code]) {
         this.bindings[code].push(callback);
      } else {
         this.bindings[code] = [callback];
      }
   },

   /**
    * Replaces the special wiki syntax characters '[', ']', and '|' with their
    * URI-encoded equivalents. This allows users to enter URIs containing these
    * characters (unencoded).
    */
   encodeWikiSyntax: function (text) {
      return text.replace(/[[\]|]/g, matches => encodeURIComponent(matches[0]));
   },
});

export function insertAtCursor(textarea, value) {
   const start = textarea.selectionStart;
   const end = textarea.selectionEnd;
   const text = textarea.value;
   textarea.value = text.slice(0, Math.max(0, start)) + value + text.substring(end, text.length);
   textarea.setSelectionRange(start + value.length, start + value.length);
}

export function insertAroundCursor(textarea, options) {
   options = {
      before: '',
      defaultMiddle: '',
      after: '',
      ...options,
   };

   const value = getSelectedText(textarea) || options.defaultMiddle;
   const pos = {
      start: textarea.selectionStart,
      end: textarea.selectionEnd,
   };
   const text = textarea.value;

   if (pos.start == pos.end) {
      textarea.value =
         text.slice(0, Math.max(0, pos.start)) +
         options.before +
         value +
         options.after +
         text.substring(pos.end, text.length);
      textarea.setSelectionRange(
         pos.start + options.before.length,
         pos.end + options.before.length + value.length
      );
   } else {
      const current = text.substring(pos.start, pos.end);
      textarea.value =
         text.slice(0, Math.max(0, pos.start)) +
         options.before +
         current +
         options.after +
         text.substring(pos.end, text.length);
      const selStart = pos.start + options.before.length;
      textarea.setSelectionRange(selStart, selStart + current.length);
   }
   return this;
}

/**
 * This is necessary instead of `document.getSelection()` because Firefox doesn't
 * properly support it, see:
 * https://github.com/mdn/content/blob/b5ff564fdfbe30924190cbb5a71afa294ca6aba0/files/en-us/web/api/document/getselection/index.md?plain=1#L64-L67
 */
export function getSelectedText(inputElement) {
   let startPos = inputElement.selectionStart;
   let endPos = inputElement.selectionEnd;
   let selectedText = inputElement.value.substring(startPos, endPos);
   return selectedText;
}
